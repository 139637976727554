{
	"type": "FeatureCollection",
	"features": [
		{
			"type": "Feature",
			"properties": {
				"id": 1,
				"name": "Porte Saint-Nicolas"
			},
			"geometry": {
				"coordinates": [
					-1.149040,
					46.155540
				],
			"type": "Point"
			}
		}
	]
}
